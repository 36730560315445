<script lang="ts">
  export let name: string;
  import AaronButton from "../Buttons/AaronButton.svelte";
  import List from "../Todo/List.svelte";
</script>

<main>
  <h1>Hello {name}! 🍺</h1>
  <p>
    Visit the <a href="https://svelte.dev/tutorial">Svelte tutorial</a> to learn
    how to build Svelte apps.
  </p>
  <AaronButton foo={33} />
  Hello
</main>

<h2>Don't forget your Todos!</h2>
<List />

<style>
  main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
